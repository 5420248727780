<template>
  <MBCourseCostume12
    chapter-id=87
    chapterPayment="/Membership/Beginner2/MBCourseBeginnerChapterPayment.vue"
    course-id="8" :items="items"/>
</template>

<script>
import MBCourseCostume12 from "@/components/Membership/Costume/MBCourseCostume12";
import chapters from './data.js';


export default {
  name: "MBCourseHead",
  components: {
    MBCourseCostume12,
  },
  data() {
    return {
      items: chapters
    }
  },
}
</script>

<style>

</style>